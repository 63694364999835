<template>
	<div>
    <CBreadcrumb :items="links" style="margin-top: -28px;"/>
		<CRow>
			<CCol sm="12">

        <CCard>
					<CCardBody>
						<TheTableHeader
							:header="'FORMULIR NON DISCLOSURE AGREEMENT (NDA)'"
							:subHeader="'No. Dokumen : FM-LAS-U2-OMT-008-006'"
						/>
					</CCardBody>
				</CCard>
				
				<CCard>
					<CCardBody>
            <CRow>
						  <CCol sm="6">
                <CInput
							    type="text"
							    label="Nama Lengkap"
							    placeholder="Masukkan nama Anda.."
							    v-model="user.name"
							    disabled
						    />
              </CCol>
              <CCol sm="6">
                <CInput
                  disabled
								  type="email"
								  label="Alamat Email"
								  placeholder="Masukkan email perusahaan.."
								  v-model="user.email"
							  />
              </CCol>
              <CCol sm="6">
                <CSelect 
								  disabled
								  :value.sync="user.company_id"
								  name="company_id"
								  label="Nama Perusahaan"
								  placeholder="Perusahaan/Instansi"
								  :options="companies"
							  />
              </CCol>
              <CCol sm="6">
                <CInput
								  type="text"
								  label="Nomor Induk Karyawan"
								  placeholder="Masukkan NIK Anda.."
								  v-model="user.NIK"
								  disabled
							  />
              </CCol>
            </CRow>

            <br>
            <p>Dengan ini menyatakan bahwa kami sebagai staf/karyawan dari Perusahaaan/Instansi tersebut di atas, memahami atau mematuhi hal berikut ini :
            <br><br>
            <strong>
            • Wajib menjaga kerahasiaan data dan informasi milik PT. Aplikanusa Lintasarta serta tidak menyalahgunakan baik untuk kepentingan sendiri, kepentingan perusahaan/instansi ataupun pihak lain.
            <br>• Client atau Pengunjung Datacenter tetap terikat pada kebijakan keamanan informasi yang telah dianut oleh perusahaan/instansi asal pelanggan tersebut.
            <br>• Client atau pengunjung Datacenter wajib memakai tanda pengenal yang telah diberikan PT. Aplikanusa Lintasarta pada waktu kunjungan ke Datacenter.
            <br>• Keamanan perangkat lunak pada perangkat Colocation merupakan tanggung jawab client atau pelanggan Datacenter.
            <br>• PT. Aplikanusa Lintasarta bertanggung jawab atas keamanan fisik baik dari segi lokasi, rak dan kandang (cage), baik ketika pelanggan atau pengunjung Datacenter sedang tidak ada di Datacenter ataupun berada di Datacenter..
            <br>• Ketika client atau pengunjung Datacenter sedang melakukan konfigurasi, keamanan aset pelanggan adalah tanggung jawab client atau pengunjung Datacenter yang bersangkutan.
            <br>• Client atau pengunjung Datacenter tidak diperbolehkan berinteraksi dengan aset yang dimiliki oleh pelanggan lain kecuali telah memiliki dasar dalam perjanjian kerja sama yang sah.
            <br>• Client yang menggunakan rak bersama (sharing) dengan pelanggan lain , dilarang menggunakan aset yang bukan milik pelanggan yang bersangkutan. Hal ini perlu mendapat perlakuan khusus untuk mencegah kabel terputus atau penempatan aset yang tidak seharusnya dari pelanggan tersebut.
            <br>• Jika client menyimpan dan melakukan transmisi cardholder data, maka tanggung jawab untuk mematuhi persyaratan PCI DSS selama bekerja dan melakukan layanan smarthand services terletak pada sisi pelanggan (client).
            <br>• Sesuai dengan PCI DSS dan ISO 27001, PT. Aplikanusa Lintasarta akan memberlakukan proses pengamanan yang ketat terhadap perlindungan informasi dan menjaga kontinuitas bisnis.
            <br>• Telah membaca & memahami Kebijakan Keamanan Informasi milik PT. Aplikanusa Lintasarta.
            </strong>
            <br><br>Apabila terjadi pelanggaran maka kami bersedia untuk bertanggung jawab sesuai hukum perundang-undangan yang berlaku.</p>
            <br><br>
            <CRow>
              <CCol sm="6"></CCol>
              <CCol sm="6">

                <div class="container">
                  <p class="sign">Tanda Tangan</p>
                  <VueSignaturePad
                    id="signature"
                    width="100%"
                    height="200px"
                    ref="signaturePad"
                    :options="options"
                  />
                </div>
                
                <div class="buttons">
                  <CButton size="sm" color="warning" @click="undo">Undo</CButton>
                  <CButton size="sm" color="danger" @click="clear">Clear</CButton>
                  <CButton size="sm" color="success" @click="save(user.signature)">Save</CButton>
                </div>

              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import http from '@/utils/http-common';
import TheTableHeader from '@/containers/TheTableHeader.vue'

export default {
	name: 'NDAForm',
  components: { TheTableHeader },
	data () {
		return {
      id: this.$store.state.user.user.id,
      companies: [],
      user: [],
      options: {
        dotSize: '0.1',
        penColor: "#333",
      },
      links: [
        {
          text: 'Home',
          href: '#/'
        }, 
        {
          text: 'Pengaturan Profile',
          href: '#/profile'
        }, 
        {
          text: 'Non-Disclosure Agreement'
        }
      ],
    }
  },
  methods: {
    toast(message) {
      this.$toast.open({
        message: message,
        type: "info", // success, info, warning, error, default
        position: "top", // top, bottom, top-right, bottom-right,top-left, bottom-left
        duration: 3000,
        dismissible: true
      })
    },
    getCompanies() {
			const self = this;
			return http.get('/companies')
			.then(function (response) {
				const data = response.data.data;
				for (let i = 0; i < data.length; i++) {
					const items = {};
					items['label'] = data[i]['company_name'];
					items['value'] = data[i]['id'];
					self.companies.push(items)
				}
			}).catch(function (error) {
				console.log(error);
			});
		},
    getUser() {
			let self = this;
			return http.get('/users/' + self.id)
				.then(function (response) {
          self.user = response.data.user
          /* response.data.user.forEach((value, index) => {
    				self.user = value;
					}); */
				}).catch(function (error) {
					console.log(error);
				});
		},
    undo() {
      this.$refs.signaturePad.undoSignature();
    },
    clear() {
      this.$refs.signaturePad.clearSignature();
      this.toast('Tandatangan dihapus.')
    },
    save(state) {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      let self = this;
      if (state) {
        return http.put('/ndas/' + self.id, {
				  user_id: self.id,
          signature: data,
			  })
			  .then(function (response) {
          console.log(response.message);
          self.toast('Tandatangan berhasil diubah.')
          self.$router.push({ path: '/profile' });
				  /* console.log(isEmpty);
          console.log(data); */
			  }).catch(function (error) {
				  console.log(error);
			  });
      } else {
        return http.post('/ndas', {
				  user_id: self.id,
          signature: data,
			  })
			  .then(function (response) {
          // console.log(response.message);
          self.toast('Tandatangan berhasil ditambahkan.')
          self.$router.push({ path: '/profile' });
				  /* console.log(isEmpty);
          console.log(data); */
			  }).catch(function (error) {
				  console.log(error);
			  });
      }
      
			
    },
    change() {
      this.options = {
        penColor: "#00f",
      };
    },
    resume() {
      this.options = {
        penColor: "#c0f",
      };
    },
  },
  mounted: function() {
		this.getUser();
    this.getCompanies();
	}
}
</script>
<style scoped>
#signature {
  border: solid 2px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.container {
  width: 100%;
  padding: 8px 16px;
}

.sign {
  text-align: center;
}

.buttons {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}
</style>
